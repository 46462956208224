<template>
  <div class="content">
    <chapter :subject="content.subject" :content="content.content"></chapter>
    <append-files :items="content.docFiles"></append-files>
    <append-imgs :items="content.imgFiles" :imgs="lightbox.imgs"></append-imgs>
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'

import Chapter from '@/components/share/Chapter.vue'
import AppendFiles from '@/components/share/AppendFiles.vue'
import AppendImgs from '@/components/share/AppendImgs.vue'

export default {
  components: {
    Chapter,
    AppendFiles,
    AppendImgs
  },
  emits: ['childSubject'],
  data() {
    return {
      lightbox: {
        imgs: []
      },
      content: {
        imgFiles: [],
        docFiles: [],
        subject: '',
        content: ''
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) this.contentList()
    }
  },
  mounted() {
    this.contentList()
  },
  methods: {
    async contentList() {
      let loader = this.$loading.show()
      await PublicAPI.Chapter(this.$route.meta.code)
        .then(response => {
          this.content = response.data
          for (let row of this.content.imgFiles) {
            this.lightbox.imgs.push(row.fileUrl)
          }
          this.$emit('childSubject', this.content.subject)
        })
        .catch(err => {
          console.log(err)
        })
      loader.hide()
    }
  }
}
</script>

<style></style>
